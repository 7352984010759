import 'regenerator-runtime/runtime'
import Vue from 'vue'
import Vuelidate from "vuelidate";
import Loading from "vue-loading-overlay";
import router from './routes'
import { store } from './store'
import App from './App.vue'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import moment from "moment";
import momentjsMixin from "./mixins/momentjs-mixin";
import VueRouter from 'vue-router';
import VueHtml2Canvas from 'vue-html2canvas';
import { html2canvas } from 'html2canvas';
import VueKonva from 'vue-konva';
import VueContentPlaceholders from 'vue-content-placeholders';
window.html2canvas = html2canvas;
import canMixin from "@/mixins/canMixin";
Vue.config.productionTip = false
import '@trevoreyre/autocomplete-vue/dist/style.css'

Vue.filter('cop_currency', function(value) {
    return new Intl.NumberFormat("ES-co", { style: "currency", currency: "COP" }).format(value);
});

Vue.filter('cop_currency_no_decimals', function(value) {
    return new Intl.NumberFormat("Es-co", { style: "currency", currency: "COP", minimumFractionDigits: 0 }).format(value);
});

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('YYYY/MM/DD');
    }
});

Vue.filter('formatDateTime', function(value) {
    if (value) {
        return moment(String(value)).format('YYYY/MM/DD hh:mm A');
    }
});

Vue.filter('time', function(value) {
    if (value) {
        return moment(String(value)).format('hh:mm A');
    }
});

Vue.filter('pad_left', function(value) {
    return value.toString().padStart(3, '0');
});

Vue.filter('truncate', function(value) {
    return Number(value).toFixed(2);
});

Vue.filter('formatTime', function(value) {
    if (value) {
      return moment(value, 'HH:mm:ss').format('h:mm:ss A');
    }
});

Vue.filter('timeFormat', function(value) {
    if (value) {
      return moment(value, 'HH:mm').format('h:mm A');
    }
});


Vue.use(Vuelidate);
Vue.use(Loading);
Vue.use(VueRouter);
Vue.use(VueHtml2Canvas);
Vue.use(VueContentPlaceholders);
Vue.use(VueKonva);

Vue.mixin({
    methods: {
        LoaderSpinnerShow: function() {
            this.$options.loader = this.$loading.show({ color: '#0055ff', container: null });
        },
        LoaderSpinnerHide: function() {

            this.$options.loader.hide();
        }
    }
});

Vue.mixin(momentjsMixin);
Vue.mixin(canMixin);
new Vue({
    components: { FontAwesomeIcon },
    store,
    router,
    render: h => h(App),
}).$mount('#app');
