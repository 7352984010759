<template>
    <div class="sidenav-menu">
        <div class="nav accordion" id="accordionSidenav">
            <div v-for="(option,i) in options" :key="i">
                <a
                    class="nav-link collapsed"
                    href="javascript:void(0);"
                    data-toggle="collapse"
                    :data-target="'#menu_collapse' + option.id"
                    aria-expanded="false"
                    aria-controls="collapseDashboards"
                    :key="option.id"
                >
                    <div class="nav-link-icon" v-html="option.icon"></div>
                    {{ option.name }}
                    <div class="sidenav-collapse-arrow">
                        <i class="fas fa-angle-down"></i>
                    </div>
                </a>
                <div
                    class="collapse"
                    :id="'menu_collapse' + option.id"
                    :key="'menu_collapse' + option.id"
                    data-parent="#accordionSidenav"
                >
                    <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavPages">
                        <div v-for="(sub,i) in option.options" :key="i">
                            <div v-if="sub.options.length">
                                <a class="nav-link collapsed" href="javascript:void(0);" data-toggle="collapse" :id="'menu_option_'+sub.id" :data-target="'#subItemsCollapse_'+sub.id" aria-expanded="false" :aria-controls="'subItemsCollapse_'+sub.id">
                                    <div class="nav-link-icon" v-html="sub.icon"></div>
                                    {{ sub.name }}
                                    <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
                                </a>
                                <div class="collapse" :id="'subItemsCollapse_'+sub.id" :data-parent="'#menu_option_'+sub.id">
                                    <nav class="sidenav-menu-nested nav">
                                        <template v-for="subitem in sub.options">
                                            <router-link
                                                v-if="subitem.app === app.name"
                                                :key="'link_' + subitem.id"
                                                :to="subitem.route"
                                                class="nav-link"
                                                v-html="subitem.icon + ' ' + subitem.name">
                                            </router-link>
                                            <a
                                                v-else
                                                :key="'link_' + subitem.id"
                                                :href="`${getAppDomain(subitem.app)}${subitem.route}`"
                                                class="nav-link"
                                                v-html="subitem.icon + ' ' + subitem.name">
                                            </a>
                                        </template>
                                    </nav>
                                </div>
                            </div>
                            <template v-else>
                                <router-link v-if="sub.app === app.name"
                                             :key="'link_' + sub.id"
                                             :to="sub.route"
                                             class="nav-link"
                                             v-html="sub.icon + ' ' + sub.name">
                                </router-link>
                                <a
                                    class="nav-link"
                                    :key="'link_' + sub.id"
                                    :href="`${getAppDomain(sub.app)}${sub.route}`"
                                    v-html="sub.icon + ' ' + sub.name"
                                    v-else>
                                </a>
                            </template>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
  </template>
  <script>
  import typeAppService from "../../../services/typeAppService";
  
  export default {
    props: ["options"],
    data() {
        return {
            appTypes : [],
        }
    },
    methods: {
        getAppDomain(name) {
            return this.appTypes.find(app => app.name === name)?.domain ?? '';
        },
    },
    async mounted() {
        const {data} = await typeAppService.index();
        this.appTypes = data;
    },
    computed: {
        app() {
            return this.appTypes.length ?
                this.appTypes.find(app => app.name === process.env.VUE_APP_NAME)
                : {};
        },
    },
  };
  </script>
  